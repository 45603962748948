import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeCourse from '../views/HomeCourse.vue'
import HomeAbout from '../views/HomeAbout.vue'
import Login from  '../views/login/index.vue'

//解决报错：Navigation aborted from “/a“ to “/b“ via a navigation guard
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  // 安检系统
  {
    path: '/imagetrain',
    component: () => import('@/views/security_imagetrain/index.vue'),
    name: 'imagetrain',
    meta: [{ title: '图像练习',breadcrumb: true }]
  },
  {
    path: '/imagetrain/exam',
    component: () => import('@/views/security_imagetrain_exam/index.vue'),
    name: 'imagetrainexam',
    meta: [{ title: '模拟考试',breadcrumb: true }]
  },
  {
    path: '/knowledgetrain',
    component: () => import('@/views/security_knowledgetrain/index.vue'),
    name: 'knowledgetrain',
    meta: [{ title: '知识练习',breadcrumb: true }]
  },
  {
    path: '/learntime',
    component: () => import('@/views/security_learntime/index.vue'),
    name: 'learntime',
    meta: [{ title: '学习时长',breadcrumb: true }]
  },
  {
    path: '/imagestore',
    component: () => import('@/views/security_imagestore/index.vue'),
    name: 'imagestore',
    meta: [{ title: '元素图库',breadcrumb: true }]
  },
  {
    path: '/mytrainingclass',
    component: () => import('@/views/security_mytrainingclass/index.vue'),
    name: 'mytrainingclass',
    meta: [{ title: '我的培训' }]
  },
  {
    path: '/mytrainingclass/courselist',
    component: () => import('@/views/security_trainingclass/index.vue'),
    name: 'mytrainingclass_courselist',
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true },
      { title: '课程列表' }
    ]
  },
  {
    path: '/mytrainingclass/trainexamlist',
    component: () => import('@/views/security_trainingclass_trainexamlist/index.vue'),
    name: 'mytrainingclass_trainexamlist',
    meta: [ 
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true },
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] }, // params: class_id 上一级参数 ['hkr_class_id'] 本页参数，但是是此上级路由接收参数
    { title: '练习列表' }
  ]
  },
  {
    path: '/course/plist/train/select',
    name: 'CourseTrainSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true },
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] }, // params: class_id 上一级参数 ['hkr_class_id'] 本页参数，但是是此上级路由接收参数
    { title: '练习列表',url: "/mytrainingclass/trainexamlist", breadcrumb: true, routeparams: [{hkr_class_id:'hkr_class_id'},{type:'hkr_type'},{course_name:'hkr_course_name'},{course_id:'hkr_course_id'}] },
      { title: '试卷页面', breadcrumb: true }
    ],
  },
  {
    path: '/course/plist/scoreshow',
    name: 'CoursePlistScoreShow',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_scoreshow/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true },
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] }, // params: class_id 上一级参数 ['hkr_class_id'] 本页参数，但是是此上级路由接收参数
    { title: '练习列表',url: "/mytrainingclass/trainexamlist", breadcrumb: true, routeparams: [{hkr_class_id:'hkr_class_id'},{type:'hkr_type'},{course_name:'hkr_course_name'},{course_id:'hkr_course_id'}] },
      { title: '成绩', breadcrumb: true }
    ],
  },
  {
    path: '/course/plist/train/select/record',
    name: 'CourseTrainSelectrecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect_record/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true,  }, 
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] },
      { title: '练习列表',url: "/mytrainingclass/trainexamlist", breadcrumb: true, routeparams: [{hkr_class_id:'hkr_class_id'},{type:'hkr_type'},{course_name:'hkr_course_name'},{course_id:'hkr_course_id'}] },
      { title: '历史记录', breadcrumb: true }
    ],
  },
  {
    path: '/course',
    name: 'course',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/course/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true,  }, 
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] }, // params: class_id 上一级参数 ['hkr_class_id'] 本页参数，但是是此上级路由接收参数
      { title: '我的课程', breadcrumb: true }
    ],
  },

  {
    path: '/course/train/select/record',
    name: 'TrainSelectrecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect_record/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true,  }, 
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] },
      { title: '我的课程', url: "/course", breadcrumb: true, routeparams: [{hkr_class_id:'hkr_class_id'},{course_id:'hkr_course_id'}] },
      { title: '历史记录', breadcrumb: true }
    ],
  },

  {
    path: '/mytrainingclass/currentexam',
    component: () => import('@/views/security_currentexam/index.vue'),
    name: 'mytrainingclass_currentexam',
    meta: [  { title: '当前考试', url: "/mytrainingclass/currentexam", breadcrumb: true },
          ]
  },
  {
    path: '/currentexam/train/select',
    name: 'CurrentExamTrainSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect/index.vue'),
    meta: [
      { title: '当前考试', url: "/mytrainingclass/currentexam", breadcrumb: true, },
      { title: '试卷页面', breadcrumb: true }
    ],
  },
  {
    path: '/train/select',
    name: 'TrainSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect/index.vue'),
    meta: [
      { title: '知识练习', url: "/knowledgetrain", breadcrumb: true, },
      { title: '试卷页', breadcrumb: true }
    ],
  },
  {
    path: '/train/select/record',
    name: 'TrainSelectrecord',
    component: () => import( '../views/security_trainselect_record/index.vue'),
    meta: [
      { title: '知识练习', url: "/knowledgetrain", breadcrumb: true },
      { title: '历史记录', breadcrumb: true }
    ],
  },
  {
    path: '/errorbooks',  /* 有历史记录按钮*/
    name: 'ErrorBooks',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_errorbooks/index.vue'),
    meta: [
      { title: "收藏夹", breadcrumb: true }
    ],
  },
  {
    path: '/errorbooks/select/record',
    name: 'ErrorBooksSelectrecord',
    component: () => import( '../views/security_trainselect_record_onlyone/index.vue'),
    meta: [
      { title: '收藏夹', url: "/errorbooks", breadcrumb: true ,routeparams: [{type:'hk_type'},{que_type:'hk_que_type'},{is_hold:'hk_is_hold'},{start_time:'hk_start_time'},{end_time:'hk_end_time'},{page:'hk_page'},{limit:'hk_limit'}] },
      { title: '详情页', breadcrumb: true }
    ],
  },
  {
    path: '/scoreshow',
    name: 'ScoreShow',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_scoreshow/index.vue'),
    meta: [
      { title: '知识练习', url: "/knowledgetrain", breadcrumb: true },
      { title: '成绩', breadcrumb: true }
    ],
  },
  {
    path: '/building',
    name: 'Building',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_buildingload/index.vue'),
    meta: [
      { title: '页面构建中...',  breadcrumb: true },
    ],
  },

  {
    path: '/notice/list',
    name: 'noticeList',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_notice_list/index.vue'),
    meta: [
      { title: "公告列表",  breadcrumb: true },
    ],
  },
  {
    path: '/theory',
    name: 'Theory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/security_knowledge_theory/index.vue'),
    meta: [{ title: '理论知识', breadcrumb: true }]   //有翻译的

  },
  {
    path: '/theory/course',
    name: 'TheoryCourse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/security_knowledge_theory_course/index.vue'),
    meta: [
      { title: '理论知识', url: "/theory", breadcrumb: true,  }, 
      { title: '我的课程', breadcrumb: true }
    ],
  },
  {
    path: '/',
    name: 'Home',
    // component: Home
    redirect: '/login'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index.vue')
  },
  {
    path: '/home/course',
    name: 'HomeCourse',
    component: HomeCourse
  },
  {
    path: '/home/generalquestions',
    name: 'HomeGeneralQuestions',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeGeneralQuestions.vue')
  },
  {
    path: '/home/about',
    name: 'HomeAbout',
    component: HomeAbout
  },
    //反馈
    {
      path: '/Feedback',
      name: 'feedback',
      component: () => import('../views/feedback/index.vue'),
    },
  {
    path: '/user',
    name: 'UsesrCenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },


]

const router = new VueRouter({
  routes
})

export default router
