import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken,removeToken } from '@/utils/auth'
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests 会涉及到内部实时服务器传输
  timeout: 500000000000000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {

      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
     
        config.headers['Authorization'] = 'bearer ' + getToken()


    }
    if (config.contentType === "json") {
      config.headers['Content-Type'] = "application/json; charset=UTF-8"
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom status
   * Here is just an example
   * You can also judge the status by HTTP Status status
   */
  response => {
    const res = response.data
    if (res.code === 10401) {
      // to re-login
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        showClose: false,
        showCancelButton: false,
        confirmButtonText: 'Re-Login',
        // cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      }).catch(() => {
        store.dispatch('user/logout').then(() => {
          location.reload()
        })
      })
    }

    else if (res.code == 1) {
      //您的账号已在其它设备登录！
      if(res.msg == "您的账号已在其它设备登录！" || res.msg == "用户不存在或被禁用"){
        removeToken()
        store.dispatch('user/logout')
        router.push(`/login?message=repeatlogin`)
        return 
      }else{
        Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(new Error(res.message || 'Error'))
      }

      
    } else {
      return res
    }
    //if the custom code is not 1, it is judged as an error.
    // if (res.status !== 1) {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.status === 50008 || res.status === 50012 || res.status === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
