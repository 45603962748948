<template>
  <div>
    <HomeHeader />
    <div style="width: 1200px; margin: 0 auto" v-if="coursedata != undefined">
      <div class="banner">
        <div class="na_breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ homedata.nav }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="hula">
          <div class="title">
            {{ homedata.title }}
          </div>
           <!-- <div
            class="showbut"
            v-if="
              $store.state.user.name == '' || $store.state.user.name == 'sample'
            "
          > -->
          <div
            class="showbut"
          >
           <!-- @click="tryuselogin(homedata.courseurl)" -->
           
            <el-button
              @click="hrefurl('/files/icaosystemvideo.mp4')"
              type="primary"
              plain
              >系统演示 <i class="el-icon-s-management"></i
            ></el-button>
            <!-- <el-button
              @click="tryuselogin(homedata.examurl)"
              type="primary"
              plain
              >考试体验 <i class="el-icon-s-claim"></i
            ></el-button> -->
          </div>
        </div>
        <div
          class="inbut"
          v-if="roles.indexOf(parseInt($route.query.cate) + 1 + '') != -1"
        >
          <el-button @click="checktocourse(homedata.courseurl)" type="success"
            >进入课程 <i class="el-icon-s-management"></i>
          </el-button>
          <el-button @click="checktocourse(homedata.examurl)" type="primary"
            >进入考试 <i class="el-icon-s-claim"></i
          ></el-button>
        </div>
        <div class="inbut" v-else>
          <el-button @click="topay()" type="warning"
            >课程购买 <i class="el-icon-shopping-cart-full"></i>
          </el-button>
        </div>
      </div>

      <div class="hometitle">
        <div class="ceng">
          <span>系统介绍</span>
        </div>
      </div>
      <div class="tabscontent" v-if="$route.query.cate == 0">
        <el-tabs v-model="activeName">
          <el-tab-pane label="概述" name="first">
            <div
              style="
                width: 95%;
                margin: 0 auto;
                font-size: 16px;
                padding-bottom: 50px;
                line-height: 28px;
              "
            >
              <div class="tiptitle"><strong>概述</strong></div>
              <div v-html="homedata.gs_gaisu"></div>
              <div class="tiptitle"><strong>功能</strong></div>
              <div v-html="homedata.gs_gongneng"></div>
              <div class="tiptitle"><strong>作用</strong></div>
              <div v-html="homedata.gs_zuoyong"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="自主学习" name="second">
            <div style="padding: 50px">
              <div style="text-align: center; font-size: 24px">
                此课程包含{{ coursedata.length }}门课程及词汇速查
              </div>
            </div>
            <div class="selflearn">
              <div
                class="item"
                v-for="(item, index) in coursedata"
                :key="index"
              >
                <div class="fl bhao">
                  <div class="flname">课程</div>
                  <div class="flnum">{{ index + 1 }}</div>
                  <div class="flzj">{{ item.coursebook.length }}个章节</div>
                </div>
                <div class="fl bcke">
                  <div class="coursename">
                    <span class="zh_cn" v-html="item.name_en"></span>
                    <span class="eng"> （{{ item.name }}） </span>
                  </div>
                  <p style="text-indent: 2em" v-html="item.name_content"></p>
                  <el-collapse>
                    <el-collapse-item title=" 课程目录" name="1">
                      <div
                        style="float: left; width: 50%; line-height: 35px"
                        v-for="(itemi, indexi) in item.coursebook"
                        :key="'km' + indexi"
                      >
                        <a
                          
                          @click="checktocoursekitem(index, indexi)"
                          v-if="itemi.url != ''"
                          >{{ itemi.title }}</a
                        >
                        <span v-else>{{ itemi.title }}</span>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
                <div style="clear: both"></div>
              </div>

              <!--附加-->
              <div
                class="item"
                v-for="(item, indexfj) in homedata.zzxx_fj"
                :key="'fj' + indexfj"
              >
                <div class="fl bhao">
                  <div class="flname">{{ item.no }}</div>
                </div>
                <div class="fl bcke">
                  <div class="coursename">
                   <a target="_blank" :href="item.url" class="zh_cn">{{ item.name_en }}</a> 
                    <span class="eng"> （{{ item.name }}） </span>
                  </div>
                  <p>
                    {{ item.name_content }}
                  </p>
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="专项训练" name="third">
            <div style="padding: 50px">
              <div style="text-align: center; font-size: 24px">训练内容</div>
            </div>
            <div class="selflearn">
              <div
                class="item"
                v-for="(item, indexzxxl) in homedata.zxxl"
                :key="'zxxl' + indexzxxl"
              >
                <div class="fl bhao">
                  <div class="flname">专题</div>
                  <div class="flnum">{{ indexzxxl + 1 }}</div>
                  <div class="flzj">{{ homedata.zxxl.length }}个专题</div>
                </div>
                <div class="fl bcke">
                  <div class="coursename">
                    <span class="zh_cn">{{ item.name_en }} </span>
                    <span class="eng"> （{{ item.name }}） </span>
                  </div>
                  <p style="text-indent: 2em" v-html="item.name_content"></p>
                  <el-collapse>
                    <el-collapse-item title=" 训练模式" name="1">
                      <div
                        style="float: left; width: 50%; line-height: 35px"
                        v-for="(itemi, indexii) in item.coursebook"
                        :key="'zxxli' + indexii"
                      >
                        <a
                          style="cursor: pointer"
                          @click="checktocourseitem(itemi.url)"
                          v-if="itemi.url != ''"
                          >{{ itemi.title }}</a
                        >
                        <span v-else>{{ itemi.title }}</span>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="模拟考试" name="fourth">
            <div style="padding: 0px 50px 50px 50px">
              <div style="text-align: right; font-size: 24px">
               <a :href="homedata.mmks_pdf"> <el-button  type="primary" plain
                  ><i class="el-icon-download"></i> 考试大纲</el-button
                ></a>
              </div>
              <div style="text-align: center; font-size: 24px">试卷组成</div>
            </div>
            <div class="selflearn">
              <div
                class="item"
                v-for="(item, indexmm) in homedata.mmks"
                :key="'mm' + indexmm"
              >
                <div class="fl bhao">
                  <div class="flname">PART</div>
                  <div class="flnum">{{ indexmm + 1 }}</div>
                </div>
                <div class="fl bcke">
                  <div class="coursename">
                    <span class="zh_cn"> {{ item.name_en }} </span>
                    <span class="eng"> （{{ item.name }}） </span>
                  </div>
                  <p style="text-indent: 2em;" v-html="item.name_content"></p>
                  <el-divider></el-divider>
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div style="text-align:center;height:100px;">
        页面内容 正在建设中...
      </div> -->

      <div class="qustion">
        <div
          style="
            margin: 0 auto;
            padding: 100px;
            text-align: center;
            font-size: 24px;
          "
        >
          常见问题
        </div>
        <div
          style="
            width: 60%;
            margin: 0 auto;
            padding: 20px;
            background-color: white;
          "
        >
          <el-collapse accordion style="margin: 0 auto">
            <el-collapse-item
              v-for="(item, indexq) in resdata.questions"
              :key="'qusetion' + indexq"
            >
              <template slot="title">
                {{ item.title }}
              </template>
              <div v-html="item.content"></div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div style="text-align: center">
        <span>{{ centerDialogcontent }}</span>
      </div>
    </el-dialog>
    <HomeFooter />
  </div>
</template>

<script>
import { fetchList, CoursefetchList } from '@/api/home'
import HomeHeader from '@/components/HomeHeader'
import HomeFooter from '@/components/HomeFooter'
export default {
  name: 'Home',
  components: { HomeHeader, HomeFooter },
  computed: {},
  data() {
    return {
      activeName: 'first',
      activeIndex: '0',
      current_banner_item_index: 0,
      current_banner_item: ['active', 'item_index', 'item_index', 'item_index'],
      bannerdata: [
        'bannerbg01.png',
        'bannerbg02.png',
        'bannerbg03.png',
        'bannerbg04.png',
      ],

      autoplay: true,

      homedata: [],
      resdata: [],
      roles: [],
      centerDialogVisible: false,
      centerDialogcontent: '',

      loginForm: {
        username: '',
        password: '',
      },
      otherQuery: {},
      coursedata: [],
      courseroutername : ''
    }
  },
  watch: {
    '$route.query.cate': function () {
      this.getlist()
      this.getcourselist()
    },
  },
  created() {
    //window.location.href = '/home.html'
    this.getlist()
    this.getcourselist()
    this.roles = this.$store.state.user.roles
  },
  methods: {
    hrefurl(url) {
      window.location.href = url
    },
    tryuselogin(url) {
      this.loginForm.username = 'sample@qq.com'
      this.loginForm.password = '123456'
      this.loading = true
      this.$store
        .dispatch('user/login', this.loginForm)
        .then(() => {
          this.$router.push({
            path: url,
            query: this.otherQuery,
          })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    checktocourse(url) {
      if (this.$store.state.user.name == '') {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未登录！'
        return
      }
      var cateid = this.$route.query.cate
      var tmeint = parseInt(cateid) + 1
      if (this.roles.indexOf(tmeint.toString()) !== -1) {
        this.$router.push({ path: url })
      } else {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未购买此课程！'
      }
    },
    topay() {
      if (this.$store.state.user.name == '') {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未登录！'
      }else{
this.$alert('无法购买', '提示', {
        });
      }
        
    },
    checktocoursekitem(chapterindex, coureindex) {
      if (this.$store.state.user.name == '') {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未登录！'
        return
      }
      var cateid = this.$route.query.cate
      
      //链接生成
      switch (parseInt(cateid)) {
        case 0:
          this.courseroutername = 'course'
          break;
        case 1:
          this.courseroutername = 'jwcourse'
          break;
        case 2:
          this.courseroutername = 'kgcourse'
          break;

        default:
          break;
      }
      var tmeint = parseInt(cateid) + 1
      if (this.roles.indexOf(tmeint.toString()) !== -1) {
        this.$router.push({
          name: this.courseroutername,
          query: { cate_id: chapterindex, menuindex: coureindex },
        })
      } else {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未购买此课程！'
      }
    },
    checktocourseitem(url) {
      if (this.$store.state.user.name == '') {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未登录！'
        return
      }
      var cateid = this.$route.query.cate
      var tmeint = parseInt(cateid) + 1
      if (this.roles.indexOf(tmeint.toString()) !== -1) {
        this.$router.push({ path: url })
      } else {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未购买此课程！'
      }
    },
    getlist() {
      fetchList().then((response) => {
        var This = this
        this.resdata = response.data
        var cateid = this.$route.query.cate
        switch (parseInt(cateid)) {
          case 0:
            This.homedata = This.resdata.pepec
            break
          case 1:
            This.homedata = This.resdata.maintenance
            break
          case 2:
            This.homedata = This.resdata.aets
            break
          case 3:
            This.homedata = This.resdata.steward
            break

          default:
            break
        }
      })
    },
    getcourselist() {
      CoursefetchList(this.$route.query.cate).then((response) => {
        this.coursedata = response.data
      })
    },
    handleSelect() {},
    bannerbgstyle(item) {
      return 'background-image: url(' + require('@/' + '/assets/' + item) + ')'
    },
    bannerchangeIndex(index) {
      this.$refs.remarkCarusel.setActiveItem(index)
      this.bannerchange(index)
    },
    bannerchange(index) {
      this.current_banner_item_index = index
      switch (index) {
        case 0:
          this.current_banner_item = [
            'active',
            'item_index',
            'item_index',
            'item_index',
          ]
          break
        case 1:
          this.current_banner_item = [
            'item_index',
            'active',
            'item_index',
            'item_index',
          ]
          break
        case 2:
          this.current_banner_item = [
            'item_index',
            'item_index',
            'active',
            'item_index',
          ]
          break
        case 3:
          this.current_banner_item = [
            'item_index',
            'item_index',
            'item_index',
            'active',
          ]
          break
        default:
          break
      }
    },
    enter(e) {
      this.autoplay = false
    },
    leave(e) {
      this.autoplay = true
    },
  },
}
</script>
<style  lang="scss" scoped>
body a {
  text-decoration: none;
}
.hometitle {
  height: 40px;
  background: url('~@/assets/hometitlebg01.svg') no-repeat center center;
  font-size: 28px;
  text-align: center;
  margin: 50px 0;
  .ceng {
    background-color: white;
    margin: 0 auto;
    width: 150px;
  }
}

.banner {
  background: linear-gradient(
    90deg,
    rgba(2, 64, 117, 1) 0%,
    rgba(209, 236, 250, 1) 100%
  );
  height: 280px;
  color: white;
  .na_breadcrumb {
    padding: 50px;
  }
  .hula {
    height: 80px;
    .title {
      padding: 0 0 0 50px;
      font-weight: 700;
      font-size: 32px;
      float: left;
    }
    .showbut {
      float: right;
      padding-right: 50px;
    }
  }
  .inbut {
    padding: 0 0 0 50px;
  }

  ::v-deep .el-breadcrumb__inner {
    color: white;
  }
  .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
    color: white;
  }
}

.tabscontent {
  width: 100%;
  ::v-deep .el-tabs__nav {
    white-space: nowrap;
    position: unset;
    transition: transform 0.3s;
    float: none;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
  }
}

.selflearn {
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #7f7f7f;
  .item {
    margin-bottom: 50px;
  }
  .fl {
    float: left;
  }
  .bhao {
    width: 10%;
    text-align: left;
    text-align: center;
  }
  .flname {
    letter-spacing: 5px;
    width: 70px;
  }
  .flnum {
    padding: 10px 0;
    font-size: 48px;
    width: 70px;
    
  }
  .flzj {
    width: 70px;
  }

  .coursename {
    font-size: 20px;
    .zh_cn{
      font-size: 20px;
      font-weight: 700;
      color: #1a76d1;
    }
  }

  .bcke {
    width: 90%;
    text-align: left;
    p {
    }
    a{
      cursor: pointer;
      color: #136ec2;
      font-size: 14px;
    }
    a:hover{
      color: #66b1ff;
      text-decoration: underline;
    }
  }
}
.tabscontent{
 ::v-deep .el-tabs__item{
    font-size: 18px;
}
}



.qustion {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(245, 247, 250, 1);
  padding-bottom: 100px;
}

.tiptitle {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid rgba(1, 122, 155, 1);
  margin: 20px 0;
}
</style>
